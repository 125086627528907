import React, { useEffect, useState } from "react";
import Breadcrumb from "../../breadCrumb/breadcrumb";
import "./aboutEcoAtm.scss";
import { capitalizeFirstLetter } from "../../../utils/utils";
import { useTranslation, Trans } from "react-i18next";
const AboutEcoAtm = ({ state, stateAbbr, city, locationName = null }) => {
  const cityFormatted = capitalizeFirstLetter(city);
  const stateFormatted = capitalizeFirstLetter(state);
  // const stateAbbrFormatted = capitalizeFirstLetter(stateAbbr);
  // const locationNameFormatted = capitalizeFirstLetter(locationName);

  const getStateAndCity = () => {
    let content;
    if (stateFormatted && city) {
      content = `${cityFormatted}, ${stateAbbr?.toUpperCase()}`;
    } else if (stateFormatted && !city) {
      content = stateFormatted;
    } else {
      content = "";
    }
    return content;
  };

  const resultStateCity = getStateAndCity();
  const [breadcrumbListState, setBreadcrumbListState] = useState([]);
  const [altLocationName, setaltLocationName] = useState(null);

  useEffect(() => {
    let breadcrumbList = [];

    // state = capitalizeFirstLetter(state);
    // city = capitalizeFirstLetter(city);
    // stateAbbr = capitalizeFirstLetter(stateAbbr);

    breadcrumbList.push({ displayText: "ecoATMLocator", url: "/" });

    if (stateFormatted) {
      breadcrumbList.push({
        displayText: stateFormatted,
        url: `/${stateAbbr?.toLowerCase()}`,
      });
    }

    if (stateFormatted && cityFormatted) {
      breadcrumbList.push({
        displayText: locationName ? cityFormatted : resultStateCity,
        url: `/${stateAbbr?.toLowerCase()}/${cityFormatted
          ?.toLowerCase()
          ?.replace(" ", "-")}`,
      });

      if (locationName) {
        // locationName = capitalizeFirstLetter(locationName);

        if (locationName.toLowerCase().includes("walmart")) {
          setaltLocationName(
            locationName.replace(/[0-9]/g, "") + " " + resultStateCity
          );
        } else {
          setaltLocationName(locationName + " " + resultStateCity);
        }
        breadcrumbList.push({
          displayText: locationName + ", " + cityFormatted,
          url: "/",
        });
      } else {
        setaltLocationName(resultStateCity);
      }
    } else {
      setaltLocationName(null);
    }

    setBreadcrumbListState(breadcrumbList);
  }, [stateFormatted, stateAbbr, cityFormatted, resultStateCity, locationName]);
  const { t } = useTranslation();
  return (
    <div className="my-4 px-1 px-md-5">
      <Breadcrumb items={breadcrumbListState} />

      <div className="d-flex flex-column flex-md-row">
        <img
          className="mx-4 mb-4 aboutEcoAtm__kiosk"
          src="https://ecoatm.bullseyelocations.com/images/kiosk.jpg"
          alt="kiosk"
        ></img>
        <div>
          <h3 className="bullseye-green pb-2">
            ecoATM |
            {altLocationName
              ? ` ${t("about.sellMyPhoneNearMe")} | ${altLocationName}`
              : state
              ? ` ${t("about.sellMyPhone")} | ${state}`
              : " " + t("about.sellMyPhone")}
          </h3>
          <span className="bl-text-bold-500">
            {t("about.getFastCashForYourPhonesAndTablets")}
          </span>
          <p>
            {resultStateCity
              ? `  ${t("about.text1_state", { resultStateCity })}`
              : t("about.text1")}
          </p>
          <p>
            {resultStateCity
              ? `${t("about.text2_state", { resultStateCity })} `
              : t("about.text2")}
          </p>
        </div>
      </div>
      <span className="bl-text-bold-500">{t("about.ecoATMNear")}</span>
      <p>
        {resultStateCity
          ? ` ${t("about.text4_state", { resultStateCity })}`
          : t("about.text4_state")}
      </p>
      <Trans i18nKey="about.list">
        <ul>
          <li>iPhones</li>
          <li>Broken iPhones</li>
          <li>Broken Samsungs</li>
          <li>Other Apple products</li>
          <li>Android tablet devices</li>
          <li>Amazon readers like Kindle Fire</li>
          <li>Motorola cell phones and smartphones</li>
          <li>HTC cell phones and smartphones</li>
          <li>Blackberry personal data devices</li>
          <li>Samsung Devices including the Galaxy S series, Note and more</li>
        </ul>
      </Trans>
      <span className="bl-text-bold-500">
        {t("about.tradeInYourPhoneToday")}
      </span>
      <p>{t("about.text6")}</p>
      <p>{t("about.text7")}</p>
    </div>
  );
};

export default React.memo(AboutEcoAtm);
